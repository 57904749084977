import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';

export const SPEAKER_ACTION_BUTTON_MESSAGE_FRAGMENT = `
  fragment speakerActionButtonMessageFragment on Speaker {
    uid
    firstName
    lastName
    prefix
    suffix
    name
    jobTitle
    employerName
    photoFileResource {
      ...fileResourceBaseFragment
    }
    user {
      ...communityUserBaseFragment
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
